.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #D7D7D7;
  border-radius: 30px;
  max-width: 350px;
  height: 40px;
  margin: auto;
}

.input {
  font-weight: 500;
  font-size: 13px;
  color: #707070;
  flex: 1;
  padding: 7px 6px 7px 6px;
  margin: 0 6px;
}

.showPasswordBtn {
  cursor: pointer;
  background: transparent;
  padding: 5px 5px 0 5px;
  margin-right: 10px;
}

.showPasswordBtn:hover {
  opacity: 0.7
}

.container {
  margin-bottom: 10px;
}

.message {
  padding: 0 15px;
  font-size: 12px;
  color: #E51A1A;
  margin: auto;
  max-width: 350px;
  width: 100%;
  display: block;
  margin-top: 5px;
}

.borderError {
  border-color: #E51A1A;
}
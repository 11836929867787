.homeLogin {
  position: relative;
}

.homeLogin::after {
  content: url('../../static/images/homepage-circle-2.svg');
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 0;
  color: #132787;
}

@media (max-width: 991px) {
  .homeLogin::after {
    display: none;
  }

  .homeLogin .row {
    justify-content: center;
  }

  .registerAdvantagesSingleWrapper {
    width: auto;
  }
}

@media (max-width: 767px) {
  .registerAdvantagesSingleWrapper {
    width: 100%;
  }
}

.homeLoginInner {
  position: relative;
  padding: 55px 0 235px;
}

@media (max-width: 767px) {
  .homeLoginInner {
    padding: 20px 0 40px;
  }
}

.homeLoginInner::before {
  content: url('../../static/images/homepage-circle-1.svg');
  display: inline-block;
  position: absolute;
  bottom: 30px;
  left: 0;
  color: #132787;
}

@media (max-width: 767px) {
  .homeLoginInner::before {
    bottom: -98px;
    z-index: 0;
  }
}

@media (min-width: 992px) {
  .homeLogin .row {
    margin: 0 -10px;
  }

  .homeLogin .row > * {
    padding: 0 10px;
  }
}

.loginBox {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 590px;
  padding: 34px 0 25px;
  border-radius: 30px;
}

.loginBoxTitle {
  margin: 0 0 18px;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  color: #0b0a0b;
}

.loginBoxBtn {
  background: #3b5998;
  border-radius: 50px;
  padding: 13px 32px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  cursor: pointer;
}

.loginBoxBtn:hover {
  background: #2f4a83;
}

.loginBoxBtn img {
  margin-right: 5px;
}

.registerAdvantagesTitle {
  margin: 66px 0 24px;
  text-align: center;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  color: #232224;
}

.registerAdvantagesSingle {
  background: linear-gradient(
    136.55deg,
    rgba(60, 104, 176, 0.1) -23.04%,
    rgba(255, 255, 255, 0) 65.37%
  );
  filter: drop-shadow(0px -53px 44px rgba(0, 0, 0, 0.02));
  border-radius: 30px;
  padding: 32px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #232224;
  height: 100%;
}

.registerAdvantagesSingle img {
  margin-right: 35px;
}

@media (max-width: 991px) {
  .homeLogin {
    padding: 30px 0 65px;
  }

  .registerAdvantagesTitle {
    margin: 45px 0 24px;
  }

  .registerAdvantagesSingle {
    padding: 24px 32px;
  }

  .registerAdvantagesSingleWrapper:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.loginBoxSecondary {
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  color: #9D9D9C;
  text-decoration: underline;
  margin-top: 17px;
  cursor: pointer;
}

.loginBoxSecondary:hover {
  color: black;
}

.loginBoxExtendedPadding {
  padding: 34px 0 38px;
}

.listContainer {
  margin-bottom: 0;
}

.hiddenHeader {
  display: none;
}
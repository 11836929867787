.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

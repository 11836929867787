.faqAccordionTitle {
  margin: 34px 0 22px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #232224;
}

.faqAccordionList {
  padding: 0;
  margin-bottom: 50px;
}

.faqAccordionListWithTwoColumns {
  column-count: 2;
  column-gap: 20px;
}

.faqAccordion {
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
}

.faqAccordionPanel {
  display: none;
  background-color: white;
  padding: 16px 20px;
}

.faqAccordionButton {
  background: transparent;
  cursor: pointer;
  padding: 0;
  width: 100%;
  outline: none;
  transition: 0.4s;
  text-align: left;
  position: relative;
  border: 0;
  padding-right: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #232224;
  padding: 16px 20px 16px 20px;
  display: flex;
  align-items: center;
}

.faqAccordionButtonTitle {
  flex: 1;
}

.faqAccordionButtonGradient {
  background: linear-gradient(150.55deg, rgba(60, 104, 176, 0.08) -23.04%, rgba(255, 255, 255, 0) 85.37%);
}

.faqAccordionButton:hover {
  color: #3f66ad;
}

.faqAccordionActive .faqAccordionPanel {
  display: block;
}

.faqAccordionButtonIcon {
  height: 30px;
  width: 30px;
}

.faqAccordionActive .faqAccordionButtonIcon {
  transform: rotate(180deg);
}

.faqAccordionListWithTwoColumns .faqAccordionButtonIcon:before {
  content:''
}

.faqAccordionListWithTwoColumns .faqAccordionButton {
  cursor: auto;
}

.faqAccordionListWithTwoColumns .faqAccordionButton {
  color: #232224;
}


.faqAccordionPanel {
  display: none;
  overflow: hidden;
  transition: all 0.4s;
}

.faqAccordionPanel p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #232224;
}

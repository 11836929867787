.errorPageMain {
  padding: 88px 0 160px;
}

@media (max-width: 991px) {
  .errorPageMain {
    padding: 20px 0 75px;
  }
}

.errorPageMainBox {
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: 0 auto;
  max-width: 794px;
}

.errorPageMainBoxTitle {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  color: #232224;
  display: block;
  margin: 20px 0 10px;
}

.errorPageMainBoxText {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #232224;
  max-width: 400px;
  margin-bottom: 38px;
}

@media (max-width: 991px) {
  .errorPageMainBoxText {
    margin-bottom: 20px;
  }
}

.errorPageMainBoxBtn {
  background: #3b5998;
  border-radius: 50px;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.errorPageMainBoxBtn:hover {
  background: #f18606;
}

.errorPageMainBoxBtn img {
  margin-right: 5px;
}

.header {
  background-color: #fff;
  padding: 16px 0;
}

.headerNav {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerNavLogo {
  /* silence */
}

@media (max-width: 991px) {
  .headerNav img {
    max-width: 200px;
  }
}

@media (max-width: 767px) {
  .headerNav img {
    max-width: 170px;
  }
}

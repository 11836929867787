.footer {
  background-color: #fff;
  padding: 18px 0;
  z-index: 2;
  position: relative;
  margin-top: auto;
}

.footerSubscribers {
  composes: footer;
  padding: 18px 0 95px !important;
}

.footerContainer {
  composes: container from '../../index.css';
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerList {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.footerList li:not(:last-of-type) {
  margin-right: 20px;
}

.footerList li a {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #707070;
}

.footerCopyrights {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
}

@media (max-width: 767px) {
  .footer {
    padding: 14px 0;
  }

  .footerContainer {
    flex-direction: column;
  }

  .footerList {
    margin-bottom: 10px;
  }

  .footerSubscribers {
    margin-bottom: 0;
    padding-bottom: 115px !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.modalHeader {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  color: #0B0A0B;
  margin-bottom: 26px;
}

.loginBoxBtn {
  background: #F18606;
  border-radius: 50px;
  padding: 13px 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color:white;
  cursor: pointer;
  align-self: center;
  margin-top: 20px;
}


.loginBoxBtn:hover {
  background: #c4700a;
}

.externalLink {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #9D9D9C;
  text-decoration: underline;
  margin-bottom: 25px;
  cursor: pointer;
  align-self: center;
  margin-top: 3px;
}

.confirmDeletePopupCloseBtn {
  position: absolute;
  right: 0px;
  top: -20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
}

.confirmDeletePopupCloseBtn:hover {
  opacity: 0.5
}
.facebookProfile {
  display: flex;
  align-items: center;
  position: relative;
}

.userMenu {
  cursor: pointer;
}

.userMenu::after {
  content: url('../../static/images/facebook-status-arrow.svg');
  position: absolute;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
}

.userMenuActive::after {
  transform: translateY(-50%) rotate(-180deg);
}

@media (max-width: 991px) {
  .facebookProfile::after {
    right: -7px;
  }
}

.facebookProfile .facebookProfileStatus {
  display: flex;
  align-items: center;
  background: rgba(22, 197, 104, 0.03);
  border-radius: 50px;
  padding: 8px 68px 8px 15px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}

@media (max-width: 991px) {
  .facebookProfile .facebookProfileStatus {
    padding: 8px 30px 8px 15px;
  }
}

@media (max-width: 767px) {
  .facebookProfile .facebookProfileStatus {
    margin-right: 5px;
  }
}

.facebookProfileStatus:after {
  content: url('../../static/images/facebook-status-arrow.svg');
  position: absolute;
  right: 11px;
  top: 44%;
  transform: translateY(-50%);
}

.facebookProfileStatusActive:after {
  transform: rotate(180deg);
  top: 33%;
}

.facebookProfile .facebookProfileStatus .facebookProfileStatusInfo {
  font-size: 14px;
  line-height: 18px;
  padding-left: 9px;
}

.facebookProfileActive .facebookProfileStatus {
  border: 1px solid #16c568;
}

.facebookProfileActive .facebookProfileStatus .facebookProfileStatusInfo {
  color: #16c568;
}

.facebookProfileActive .facebookProfileStatus svg path {
  stroke: #16c568;
}

.facebookProfileInactive .facebookProfileStatus {
  padding: 8px 30px 8px 15px;
  background: rgba(229, 26, 26, 0.03);
  border: 1px solid #e51a1a;
}

.facebookProfileInactive .facebookProfileStatus .facebookProfileStatusInfo::after {
  content: url('../../static/images/warning-icon.svg');
  margin-bottom: -3px;
  margin-left: 7px;
}

.facebookProfileInactive .facebookProfileStatus .facebookProfileStatusInfo {
  color: #e51a1a;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .facebookProfile .facebookProfileStatus .facebookProfileStatusInfo {
    display: none !important;
  }
}

.facebookProfile.facebookProfileInactive .facebookProfileStatus svg path {
  stroke: #e51a1a;
}

.facebookProfileMenuList {
  position: absolute;
  transform: translateY(26px);
  display: none;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.09);
  padding: 20px;
  top: 16px;
  width: 265px;
  border-radius: 15px;
  left: -30px;
  z-index: 10;
}

.facebookProfileMenuListActive {
  display: block;
}

@media (max-width: 991px) {
  .facebookProfileMenuList {
    left: unset;
    right: 0;
    width: 200px;
  }
}

.facebookProfileMenuListInner {
  padding-bottom: 18px;
  border-bottom: 1px solid #d7d7d7;
  transition: all 0.3s;
}

.facebookProfileMenuListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.facebookProfileMenuListItem:last-child {
  margin-bottom: 0;
}

.facebookProfileMenuListItemName {
  font-size: 13px;
  line-height: 17px;
  color: #232224;
  max-width: 150px;
}

@media (max-width: 991px) {
  .facebookProfileMenuListItemName {
    font-size: 11px;
  }
}

.facebookProfileMenuListItemStatus {
  font-size: 12px;
  line-height: 14px;
  color: #9d9d9c;
}

.facebookProfileMenuListItemActive .facebookProfileMenuListItemStatus {
  color: #16c568;
}

@media (max-width: 991px) {
  .facebookProfileSettings {
    margin-right: 5px;
  }

  .facebookProfileSettingsSpan {
    display: none;
  }
}

.facebookProfileSettings a {
  display: flex;
  align-items: center;
}

.facebookProfileSettings a img {
  margin-right: 7px;
  width: 41px;
  height: 41px;
  border-radius: 100%;
  object-fit: cover;
}

.facebookProfileSettings a span {
  font-size: 14px;
  line-height: 18px;
  color: #0b0a0b;
}

.facebookProfileSettings {
  position: relative;
}

.facebookProfileSettingsMenuList {
  position: absolute;
  transform: translateY(26px);
  display: none;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 9px 25px rgb(0 0 0 / 9%);
  padding: 20px;
  top: 16px;
  width: 215px;
  border-radius: 15px;
  left: 0;
  z-index: 10;
}

.facebookProfileSettingsMenuListActive {
  display: block;
}

@media (max-width: 1300px) {
  .facebookProfileSettingsMenuList {
    left: unset;
    right: 0;
    width: 180px;
  }
}

.facebookProfileSettingsMenuListItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.facebookProfileSettingsMenuListItem:last-child {
  margin-bottom: 0;
}

.facebookProfileSettingsMenuListItem svg {
  margin-right: 10px;
}

.facebookProfileSettingsMenuListItem span {
  font-size: 14px;
  line-height: 18px;
  color: #0b0a0b;
}

.facebookProfileSettingsMenuListItem:hover span {
  color: #3c68b0;
}

.facebookProfileSettingsMenuListItem:hover svg path {
  stroke: #3c68b0;
}

.pagesLoader{
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
  font-family: Quicksand, sans-serif;
}

main {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

*,
*:after,
*:before {
  margin: 0;
  list-style: none;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  font-family: inherit;
}

html,
body {
  font-style: normal;
  margin: 0 auto;
  position: relative;
  font-size: 16px;
  background: #f7faff;
}

.btn-remove {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  background: transparent;
  border: none;
  color: #e51a1a;
  cursor: pointer;
}

.btn-remove:hover,
.btn-remove:active {
  color: #3c68b0;
}

.btn-orange {
  background: #f18606;
  border: 1px solid #f18606;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 13px 19px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.btn-orange:hover {
  background: #3c68b0;
  border: 1px solid #3c68b0;
}

button:disabled,
button[disabled] {
  border: 1px solid #f0f0f0;
  background: #f0f0f0;
  color: #9d9d9c;
}

button:disabled:hover,
button[disabled]:hover {
  border: 1px solid #f0f0f0;
  background: #f0f0f0;
  color: #9d9d9c;
}

.btn-settings {
  display: block;
  text-align: center;
  background: #f18606;
  border: 1px solid #f18606;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 7px 19px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.btn-settings:hover {
  background: #3c68b0;
  border: 1px solid #3c68b0;
}

@media (max-width: 991px) {
  .btn-settings {
    font-size: 12px;
  }
}

.btn-red {
  background: #e51a1a;
  border: 1px solid #e51a1a;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 13px 19px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.btn-red:hover {
  background: #bd1515;
  border: 1px solid #bd1515;
}

.btn-border {
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #0b0a0b;
  padding: 13px 19px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.btn-border:hover {
  background: #3c68b0;
  border: 1px solid #3c68b0;
  color: #fff;
}

.green-button {
  padding: 9px 10px 9px 8px;
  background: #16c568;
  border-radius: 50px;
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.green-button:hover {
  background: #07964a;
}

.container {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.container-md {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding: 0 20px;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

.toggle {
  cursor: pointer;
  display: inline-flex;
  align-content: center;
}

.toggle-switch {
  display: inline-block;
  background: #d7d7d7;
  border-radius: 16px;
  width: 35px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch:after {
  content: '';
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 18px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}

/* .toggle.active .toggle-checkbox + .toggle-switch, */
.toggle-checkbox:checked + .toggle-label + .toggle-switch {
  background: #16c568;
}

/* .toggle.active .toggle-checkbox + .toggle-switch:before, */
.toggle-checkbox:checked + .toggle-label + .toggle-switch:before {
  left: 2px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-right: 10px;
  position: relative;
  top: 2px;
  font-size: 12px;
  line-height: 0;
  color: #707070;
}

.toggle-label::before {
  content: attr(data-disable);
}

.toggle-checkbox:checked + .toggle-label {
  font-weight: 700;
}

.toggle-checkbox:checked + .toggle-label::before {
  content: attr(data-active);
}

.pageLoader {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: all 2s;
}

.Toastify__toast-body small {
  display: inline-block;
  width: 100%;
  color: #e51a1a;
  font-size: 10px;
}

@import "../node_modules/react-tooltip/dist/react-tooltip.css";

.styles-module_warning__SCK0X {
  max-width: 85vw;
  border-radius: 25px;
}

@media (min-width: 992px) {
  .styles-module_warning__SCK0X {
    max-width: 25vw;
  }
}